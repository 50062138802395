import {React, useEffect, useState} from "react";
import Header from "./Header";
import ContentPopup from "./ContentPopup";
import { Icon } from "@iconify/react";
import assignmentCheck from "@iconify-icons/zmdi/assignment-check";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import Footer from "./Footer";
function Thankyou (props) {
  const [showPopup, setShowPopup] = useState(props?.history?.location?.state?.popup);
  const [Storage1, setStorage1] = useState('');
  const [name, setName] = useState("hamza");


      
     localStorage.removeItem("returnthankyou",true)
     localStorage.removeItem("voucher","")
     localStorage.removeItem('returnpage',false)
     localStorage.removeItem('userformopen')
     localStorage.removeItem('userform')
  
      if (localStorage.getItem("First Name") !== null)
      
                 if (window.performance) {
                    if (performance.navigation.type == 1) {
                       console.log( "" );
                     state.Storage1 = localStorage.getItem('First Name');
                     state.Storage2 = localStorage.getItem('Last Name');
                     state.Storage3 = localStorage.getItem('City');
                     state.Storage4 = localStorage.getItem('State');
                     state.Storage5 = localStorage.getItem('Address');
                     state.Storage6 = localStorage.getItem('Zip Code'); 
                       
                    }
                     else {  
                    
                      localStorage.setItem('First Name', props.history.location.state.name);
                      state.Storage1=props.history.location.state.name;
                      localStorage.setItem('Last Name', props.history.location.state.lname);
                      state.Storage2=props.history.location.state.lname;
                      localStorage.setItem('City', props.history.location.state.city);
                      state.Storage3=props.history.location.state.city;
                      localStorage.setItem('State', props.history.location.state.state);
                      state.Storage4=props.history.location.state.state;
                      localStorage.setItem('Address', props.history.location.state.address);
                      state.Storage5= props.history.location.state.address;
                      localStorage.setItem('Zip Code',  props.history.location.state.zipcode);
                      state.Storage6= props.history.location.state.zipcode;
                      console.log( "");
                    }
                  }
              

                        // if (props.history.location.state.zipcode == null) {
                          
                        //    return   props.history.push('/');
                        //   }
  

    const isVoucher = localStorage.getItem('Thankyou')
    const Voucher = localStorage.getItem('voucher')
    if(isVoucher != 'true' || Voucher=="undefined" || props.history.location.state.zipcode== "undefined") {
      localStorage.setItem('isVoucher',false)  
      return <Redirect to='/' />
    } else {
      localStorage.setItem("Thankyou",false)
    }    
    return (
      <>
        <div className="container-fluid">
          <Header />
          {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association.</div> */}
          <div className="white-box thank">
            <h1 className="text-center">Thank You! </h1>
            <Icon icon={assignmentCheck} />
            <p>Thank You!  {props.history.location.state.name}</p>
            <ul className="info-data">
              <li key="uniqueId56">
                { props.history.location.state.name}{" "}
                { props.history.location.state.lname}
              </li>
              <li key="23ff">{props.history.location.state.city}</li>
              <li key="uniqueId1">
                {props.history.location.state.state}{" "}
                {props.history.location.state.address}{" "}
                {props.history.location.state.zipcode}
              </li>
            </ul>
            <p>
            Once your order has processed, the link to your rewards card will be EMAILED to you by the Virtual Rewards Center.
            </p>
            <p>
            You will need to click that link to finalize your order and select your preferred delivery method.
            </p>
            <p>
            Please do not discard your certificate until you receive your reward card.
            </p>
            <p>If you have any questions, please call <span className="green">1-866-ELITE-21 </span></p>
            <p>or email us at <span class="blue"><a href="mailto:customercare@eliterewards.biz"><strong>customercare@eliterewards.biz</strong></a></span></p>
            <p> and one of our reward card specialists will get back to you.</p>
            <p>Enjoy your card!</p>
            <p className="note">PLEASE NOTE:  If the card you have selected is out of stock, we will send you a REWARD CARD instead, which can be used at thousands of retailers instead of just one place!</p>
          </div>
        </div>
        {
          showPopup === "true" && <ContentPopup />
        }
        <Footer />
      </>
    );
};
export default Thankyou;
