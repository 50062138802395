import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "./Header";

function ContentPopup() {
  // alert(props.hideShow);
  const [show, setShow] = useState(true);

  const handleModal = (e) => {
    setShow(!show);
  };

  return (
    <div>
      <div>
        {/* <div className="modalClass">
          <Button id="pop1" onClick={handleModal}>
            Modal
          </Button>
        </div> */}

        <Modal show={show} onHide={handleModal} id="modelContent">
          <Button id="pop-close1" onClick={handleModal}>
            &times;
          </Button>
          <Modal.Header id="header">
            <img
              id="popImg"
              src="https://images-cloud.s3.ap-south-1.amazonaws.com/DemoBoston-logo.png"
              alt="img-responsive"
            />
          </Modal.Header>
          <div className="model-body">
            <ul class="llist">
              <li>
                Once your order has processed, the link to your rewards card
                will be EMAILED to you by the Card Services BH Network.
              </li>
              <li>
                You will need to click that link to finalize your order and
                select your preferred delivery method.
              </li>
              <li>
                You will need to finalize your order by following the
                instructions in that email from{" "}
                <a href="mailto:info@demo.bostonfam-resttest.com">
                  info@demo.bostonfam-resttest.com
                </a>
              </li><br/>
              <p>
              <b>
                PLEASE NOTE: You only have 30 days to activate your card. Check
                your inbox, junk and spam and contact our customer service line
                if you haven't received the email within the week.
              </b>
            </p>
            </ul>
   
          </div>
          {/* <Modal.Footer>
              <Button id="btn1" onClick={handleModal}>
                Close
              </Button>
            </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default ContentPopup;
