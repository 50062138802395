import React from "react";
import { useState } from "react";
import "./style.css";
import EmailBox from "./EmailBox";
import SampBox from "./SampBox";
import ContactUs from "./ContactUs";
import Header from "./Header";
import Footer from "./Footer";
import GiftBox from "./GiftBox";
import UserformSurvey from "./UserformSurvey";
import "bootstrap/dist/css/bootstrap.min.css";
import Recaptcha from "react-recaptcha";
import { Redirect } from "react-router-dom";
import axios from "axios";

function RegisterForm(props) {
  const [fields, setFields] = useState({ voucher: "" });
  const [errors, setErrors] = useState({});
  const [i_agree, setI_agree] = useState(false);
  const [i_google, setI_google] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  // const [checkCount, setCheckCount] = useState(0);
  // const [error_api, setError_api] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [value, setValue] = useState("");
  // const [items, setItems] = useState([]);
  // const [checking, setChecking] = useState(false);
  const [person, setPerson] = useState(null);
  const [voucher_two, setVoucher_two] = useState("");
  const [checkvoucherfield, setCheckvoucherfield] = useState(
    "The voucher number is required."
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const voucher_two = e.target.value;
    setFields((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setVoucher_two(voucher_two);
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const handleChangecheckboxgoogle = (e, value) => {
    setI_google(i_google);
  };

  const verifyCallback = (response) => {
    let errors = {};
    if (response) {
      setCaptcha(true);
    } else {
      setErrors(errors);

      return false;
    }
  };

  const submituserRegistrationForm = async (e) => {
    console.log("value :");
    e.preventDefault();

    if (validateForm()) {
      // let fields = {};
      let voucher_two = "";

      setFields({ voucher: "" });
      setVoucher_two(name);

      let errors = {};
      const checkCount = 0;

      if (checkCount == 0) {
        if (i_agree) {
          setVoucher_two(name);

          var username = process.env.REACT_APP_API_USERNAME;
          var password = process.env.REACT_APP_API_PASSWORD;
          const token = Buffer.from(`${username}:${password}`, "utf8").toString(
            "base64"
          );
          var bodyFormData = new FormData();
          bodyFormData.append("certificate", fields.voucher);
          // alert(Buffer.from(`${username}:${password}`, "utf8").toString( "base64" ))

          let voucher_two = fields.voucher;

          try {
            const response = await axios({
              method: "post",
              url: process.env.REACT_APP_API_Link + "validateVoucher",
              data: bodyFormData,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${token}`,
                "Access-Control-Allow-Origin": process.env.REACT_APP_API_ORIGIN,
              },
            });
            console.log("value :", response.data);
            if (response.data.status.includes("true")) {
              localStorage.setItem("isVoucher", true);
              localStorage.setItem(
                "denomination",
                response.data.data[0].denomination
              );
              const value = response.data.data[0].redirect;
              const status_value = response.data.status;
              if (status_value.includes("true")) {
                const pagename = value.split(" ");
                if (pagename == "userform") {
                  localStorage.setItem("voucher", voucher_two);

                  props.history.push({
                    pathname: "/UserformSurvey",
                    state: {
                      voucher_two: voucher_two,
                    },
                  });
                  window.scrollTo(0, 0);
                }
              }
            }

            if (!response.data.status.includes("true")) {
              console.log("value :", response.data.message);
              if (
                !response.data.status.includes("true") &&
                response.data.message.redirect !== undefined &&
                response.data.message.redirect.includes("cert_details")
              ) {
                localStorage.setItem("cert_details", true);
                const detail_data = response.data.message.data;
                props.history.push({
                  pathname: "/cert_details",
                  state: {
                    fname: detail_data.first_name,
                    lname: detail_data.last_name,
                    address: detail_data.address,
                    city: detail_data.city,
                    state: detail_data.state,
                    zip: detail_data.zip,
                    phone: detail_data.phone,
                    email: detail_data.email,
                    cardImage: detail_data.cardImage,
                    cardName: detail_data.cardName,
                  },
                });
              } else {
                errors["voucher"] = response.data.message;
                // checkingvoucherfield = response.data.message;
                setCheckvoucherfield(response.data.message);

                setErrors(errors);

                return false;
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };
  const validateForm = () => {
    // let fields = fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["voucher"]) {
      formIsValid = false;
      errors["voucher"] = checkvoucherfield;
      //  this.state.checkingvoucherfield = response.data.message;
      // this.state.checkingvoucherfield = response.data.message;
      setErrors(errors);

      return false;
    }

    if (!i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";
      setErrors(errors);

      return false;
    }
    if (!captcha) {
      formIsValid = true;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);

      return false;
    }
    return formIsValid;
  };
  const onLoadRecaptcha = () => {};

  const existing = localStorage.getItem("returnpage");

  if (localStorage.getItem("returnpage") == "true") {
    localStorage.setItem("returnpage", false);
    localStorage.setItem("userform", false);
    const Page = localStorage.getItem("page");
    localStorage.removeItem("page");
    return <Redirect to={Page} />;
  }
  return (
    <>
      <div className="container-fluid">
        <Header />
        {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association</div> */}
        <div className="white-box">
          <h1>PLEASE READ THIS CAREFULLY</h1>
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <li key="uniqueId1">
                Enter your voucher number below<br></br>
                <strong>
                  Please Note: Voucher numbers are Case Sensitive with no
                  spaces.
                </strong>
              </li>
              <li key="uniqueId2">
                <div className="input-outer1">
                  <input
                    type="text"
                    maxLength="11"
                    placeholder="Enter Voucher Number"
                    name="voucher"
                    className="searchBox_deals vc-validate"
                    value={fields.voucher || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.voucher}
                    <div>{person}</div>
                  </div>
                </div>
              </li>
              <li key="uniqueId3">
                <EmailBox />
              </li>
              <li key="uniqueId4">
                <SampBox />
              </li>
              <li key="uniqueId40">
                <GiftBox />
              </li>
              <li key="uniqueId5">
                <ContactUs />
              </li>
              <li className="removing" key="uniqueId6">
                <label>
                  <input
                    type="checkbox"
                    id="myCheck"
                    name="test"
                    className="conditions"
                    value={fields.checkbox}
                    onChange={handleChangecheckbox}
                  />
                  <span> YES,I HAVE READ THE ABOVE INFORMATION</span>
                  <div className="errorMsg">{errors.checkederror}</div>
                </label>
                <br></br>
                <Recaptcha
                  sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                  render="explicit"
                  onloadCallback={onLoadRecaptcha}
                  verifyCallback={verifyCallback}
                />
                <div className="errorMsg">{errors.checkederrorcaptcha}</div>
                <input
                  onClick="myTimeout()"
                  type="submit"
                  name="submit"
                  id="submit"
                  value="SUBMIT"
                  className="sub-btn button"
                ></input>
              </li>
              <br />
              <li key="uniqueId7">
                Feel free to contact our customer service team
                <a href="mailto:customercare@eliterewards.biz">
                  {" "}
                  customercare@eliterewards.biz
                </a>
              </li>
              <li>
                <p>
                  PLEASE NOTE: If the card you have selected is out of stock, we
                  will send you a Mastercard Reward Card instead, which can be
                  used at thousands of retailers instead of just one place!
                </p>
              </li>
            </form>
          </ul>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default RegisterForm;
