import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
  } from "react-router-dom";
function cert_details (props) {
    const isVoucher = localStorage.getItem('cert_details')
    if(isVoucher != 'true' ||  props.history.location.state.zip== "undefined" || props.history.location.state.zip== "null") {
      return <Redirect to='/register/' />
    } else {
      localStorage.setItem("cert_details",false)
    } 
    return (
      <>
        <div className="container-fluid">
          <Header />
          {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association</div> */}
          <div className="white-box">
            <h1>Reward Card Redemption Site</h1>
            <hr />
            <h2>Contact Information</h2>
            <hr />
            <form className="form-outer register clearfix">
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <strong>First Name </strong>
                    <br />
                    {props.history.location.state.fname}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Last Name</strong>
                    <br />
                    {props.history.location.state.lname}
                  </label>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-6">
                  <label>
                    <strong>Address</strong>
                    <br />
                    {props.history.location.state.address}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>City </strong>
                    <br />
                    {props.history.location.state.city}
                  </label>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-6">
                  <label>
                    <strong>State </strong>
                    <br />
                    {props.history.location.state.state}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Zip Code</strong>
                    <br />
                    {props.history.location.state.zip}
                  </label>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-6">
                  <label>
                    <strong>Phone No </strong>
                    <br />
                    {props.history.location.state.phone}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Email </strong>
                    <br />
                    {props.history.location.state.email}
                  </label>
                </div>
                <hr />
                <h2>Bostonfam Rest Test</h2>
                <hr />
                <div className="check-boexs cen">
                  <div className="reward_cert">
                    {/* {props.history.location.state.cardName}
                    {props.history.location.state.cardImage} */}
                    <span className="insdex">
                      {props.history.location.state.cardName}
                    </span>
                    {
                                    props.history.location.state.cardImage ?
                                    <img
                                      src={
                                        process.env.REACT_APP_API_IMAGES +
                                        props.history.location.state.cardImage
                                      }
                                      className="imgcolor img-responsive"
                                    /> :
                                    <div class="pirds pirds2">
                                      <span class="rdcrdtop">
                                        <table border="0">
                                          <tr>
                                            <td>{props.history.location.state.cardName}</td>
                                          </tr>
                                        </table>
                                      </span>
                                      <span class="rdcrd">REWARD CARD</span>
                                    </div>
                                  }
                  </div>
                </div>
              
              </div>
              <a href="/" className="btn btn-md btn-primary">
                  Go Back
                </a>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
export default cert_details;
