import React from 'react';
const Header = () =>{
    return(

        <div className="row">
        <div className="col-md-12">
        <div className="logo">
        <img src="https://images-cloud.s3.ap-south-1.amazonaws.com/DemoBoston-logo.png" className="img-responsive"/>
        
         </div>
         </div>
         
         </div>
       
    );
}
export default Header;