import React from "react";
const ContactUs = () => {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src="https://images-cloud.s3.ap-south-1.amazonaws.com/ashley-mark.png" />
        
      </div>

      <div className="col-md-11">
							If you have any questions, for the fastest service contact us on our live chat, in the lower right hand corner Or contact us via email at <a href="mailto:customercare@eliterewards.biz">customercare@eliterewards.biz</a><br/>
							Or call us at <a href="tel:1-866-ELITE-21">1-866-ELITE-21</a><br/>
							Our business hours are Monday - Friday, 8:00am - 7:00pm EST.
			</div>


    </div>
  );
};
export default ContactUs;
