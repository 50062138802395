import React from "react";
const EmailBox = () => {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src="https://images-cloud.s3.ap-south-1.amazonaws.com/ashley-look.png" />
      </div>
      <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email within 7-10 business days from{" "}
        <a href="mailto:do-not-reply@bhnetwork.com">
          do-not-reply@bhnetwork.com
        </a>{" "}
        or{" "}
        <a href="mailto:do-not-reply@wgiftcard.com">
          do-not-reply@wgiftcard.com
        </a>{" "}
        - we suggest adding these email addresses to your SAFE SENDER list in
        your email settings.
      </div>
    </div>
  );
};
export default EmailBox;
