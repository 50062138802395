import React from 'react';
const SampBox = () =>{
    return(
<div className="row">
                <div className="col-md-1">
                  <img src="https://images-cloud.s3.ap-south-1.amazonaws.com/ashley-notifi.png" />
                  
                </div>
                <div className="col-md-11 mt-2">
                Please make sure to monitor your SPAM and JUNK folders as well.
                </div>
              </div>
    );  
}
export default SampBox;