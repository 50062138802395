import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  Router,
  HashRouter,
  Redirect,
} from "react-router-dom";
import RegisterForm from "./Components/RegisterForm";
import Thankyou from "./Components/Thankyou";
import cert_details from "./Components/cert_details";

import UserForm from "./Components/UserForm";
import UserformSurvey from "./Components/UserformSurvey";
// import ContentPopup from "./Components/ContentPopup";
function App() {
  const [isVoucher, setIsVoucher] = useState(false);
  useEffect(() => {
    localStorage.setItem("isVoucher", false);
    setIsVoucher(localStorage.getItem("isVoucher"));
  }, []);
  if (process.env.REACT_APP_STAGE === "PROD")
    console.log = function no_console() {};
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={RegisterForm} />
        <Route excet path="/UserformSurvey" component={UserformSurvey} />
        <Route exact path="/Thankyou" component={Thankyou} />
        <Route exact path="/cert_details" component={cert_details} />
        <Route exact path="/UserForm" component={UserForm} />
        {/* <Route exact path="/ContentPopup" component={ContentPopup } /> */}
        <Redirect excet to="/" />
        <Route exact path="*" component={RegisterForm} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
