import React from "react";
const GiftBox = () => {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src="https://images-cloud.s3.ap-south-1.amazonaws.com/ashley-gift.png" />
        
      </div>

      <div className="col-md-11" style={{fontFamily:"Arial"}}>
							Once you receive your email from <a href="mailto:do-not-reply@bhnetwork.com">do-not-reply@bhnetwork.com</a> or <a href="mailto:do-not-reply@wgiftcard.com">do-not-reply@wgiftcard.com</a>, you can use the card virtually!
							{/* <br/> <strong>*PLEASE NOTE YOUR REWARD LINK IS TIME SENSITIVE AND WILL EXPIRE IN 60 DAYS.</strong>  */}
						</div>


    </div>
  );
};
export default GiftBox;
