import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";
import HeaderLower from "./HeaderLower";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Model from "./Model";

function UserForm (props) {
  const [fields , setFields] = useState({
    name: '',
    lname: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    phonenumber: '',
    phonenumber1: '',
    phonenumber2: '',
    emailid: '',
    // imgname: '',
    // checkbox: '',

  });
  const [errors , setErrors] = useState({});
  const [states , setStates] = useState([]);
  const [value , setValue] = useState([]);
  const [value_three , setValue_three] = useState([]);
  const [i_agree , setI_agree] = useState(false);
  const [i_agree_five , setI_agree_five] = useState(false);
  // const [signoutTime , setSignoutTime] = useState(1000 * 60 * 10);
  const [signoutTime , setSignoutTime] = useState(180000);
  const [popup , setPopup] = useState("true");
  const [selectCard , setSelectCard] = useState("");
  const [valueUserform , setValueUserform] = useState("");
  const [voucherData , setVoucherData] = useState("");
  const [storage , setStorage] = useState("");
  const [istrue , setIstrue] = useState(true);
  const [show , setShow] = useState(false);
  const [checkCount , setCheckCount] = useState(0);
  const [i_agree_two , setI_agree_two] = useState(false);
  const [value_two , setValue_two] = useState([]);
  const [data_result , setdata_result] = useState("");






  const ref = useRef();
  // const  ref = React.createRef();
  useEffect(() => {
    test();
  }, [])

 
    localStorage.setItem("returnpage", true);
    localStorage.setItem("page", "/UserForm");
    if (localStorage.getItem("userformopen") == "ture") {
      localStorage.setItem("userform", true);
    }
  
    async function test()  {
    // const existing_voucher = localStorage.getItem("voucher");
    let errors = {};

    var token_value = localStorage.getItem("voucher");
   
    var username = process.env.REACT_APP_API_USERNAME;
    var password = process.env.REACT_APP_API_PASSWORD;
    const token_tow = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    var bodyFormData = new FormData();
    bodyFormData.append("certificate", token_value);
    try {
      const response = await axios({
        method: "GET",
        url: process.env.REACT_APP_API_Link + "getStates",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Basic ${token_tow}`,
          "Access-Control-Allow-Origin": process.env.REACT_APP_API_ORIGIN,
        },
      });
      var states = response.data.data;
      setStates(states);
     
      if (response.data.status == 429 || response.data.status == "429") {
        alert("Max Limit Reached. Please wait.");
      }
      if (value.includes("redirect")) {
      } else {
        errors["voucher"] = response.data.response;
      }
    } catch (err) {
      if (err.status == 429 || err.status == "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }



    try {
      const response_sheet = await axios({
        method: "post",
        url: process.env.REACT_APP_API_Link + "getCards",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Basic ${token_tow}`,
          "Access-Control-Allow-Origin": process.env.REACT_APP_API_ORIGIN,
        },
      });
      const value_three = response_sheet.data.data;
      console.log("cards :", value_three);
      setValue_three(value_three);
      // setState({
      //   value_three: value_three,
      // });
      if (value.includes("redirect")) {
      } else {
        errors["voucher"] = response_sheet.data.response;
      }
    } catch (err) {
      if (err.status == 429 || err.status == "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
    timeout();
  }

  function timeout() {
    // alert(signoutTime);
    setTimeout(function() {
        logout();
      },
      signoutTime
    );
  };


  const logout = () => {
    // alert(state.signoutTime);
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem("userform");
    localStorage.removeItem("userformopen");
    localStorage.removeItem("voucher");
    localStorage.removeItem("page");
    localStorage.removeItem("ans1");
    localStorage.removeItem("ans2");
    localStorage.removeItem("ans3");
    localStorage.removeItem("ans4");
    localStorage.removeItem("ans5");
    window.location.replace("/");
    return false;
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      }
    })
   
  }
  const saveErrors = (err) => {
    setErrors(err);
  }
  const handleChangecheckboxtwo = (e, value) => {
    setState({
      selectcard: e.target.value,
      i_agree_two: !state.i_agree_two,
    });
  };

  const handleChangecheckboxfour = (e, value) => {
    setState({
      selectsheet: e.target.value,
      i_agree_two: !state.i_agree_two,
    });
  };
  const handleChangecheckboxfive = (e, value) => {
    let cardName = e.target.value;
    setSelectCard(cardName);
    setI_agree_five(!i_agree_five);
   
  };
  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
    
  };

  const handleClick = (e) => {
    let errors = {};
    // if(state.i_agree == true && state.fields['emailid']){
    //   setState({show: !state.show});
    // }

    if (i_agree == false) {
      e.preventDefault();
      errors["agree"] = "Please accept terms & condition.";
      
      setErrors(errors);
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    // alert(validate());
    if (validate()) {
      alert("TEst")
      let fields = {};
      fields["voucher"] = "";
      fields["name"] = "";
      fields["lname"] = "";
      fields["address"] = "";
      fields["city"] = "";
      fields["state"] = "";
      fields["zipcode"] = "";
      fields["phonenumber"] = "";
      fields["phonenumber1"] = "";
      fields["phonenumber2"] = "";
      fields["emailid"] = "";
      fields["checkederror"] = "";
      fields["checkederrortwo"] = "";
      fields["checkederrorfive"] = "";
      fields["checkedboxerrornine"] = "";
      // fields["checkederrorsix"] = "";

      setFields( {} );
      if (fields["name"] == "") {
        if (i_agree) {
          props.history.push({
            pathname: "/Thankyou",
            // search: '?query=abc',
            state: {
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phonenumber: fields.phonenumber,
              phonenumber1: fields.phonenumber1,
              phonenumber2: fields.phonenumber2,
              emailid: fields.emailid,
              card: selectCard,
              popup: popup,
            },
          });
        }
      }
    }
  }
  const validate = (e) => {
    let isformIsValid = false;
    const token_value = localStorage.getItem("voucher");
    let data = {
      fname: fields.name,
      lname: fields.lname,
      address: fields.address,
      city: fields.city,
      state: fields.state,
      zip: fields.zipcode,
      phone:
        fields.phonenumber +
        fields.phonenumber1 +
        fields.phonenumber2,
      email: fields.emailid,
      choice: selectCard,
      denomination: localStorage.getItem("denomination"),
      // certificate: state.storage ? state.storage : "",
      certificate: localStorage.getItem("voucher"),
      ans1: localStorage.getItem("ans1"),
      ans2: localStorage.getItem("ans2"),
      ans3: localStorage.getItem("ans3"),
      ans4: localStorage.getItem("ans4"),
      ans5: localStorage.getItem("ans5"),
    };
    // console.log(data);
    // console.log(" Frist name :", fields?.name);
    // console.log("last name :", fields?.lname);
    // console.log("address :", fields?.address);
    // console.log("city :", fields?.city);
    // console.log("card :", selectCard);
    // console.log("email :", fields?.emailid);
    // console.log("phone :", fields?.phonenumber);
    // console.log("state :", fields?.state);
    // console.log("zip :", fields?.zipcode);
    // console.log("certificate :", storage ? state.storage : "");
    // certificate: localStorage.getItem("voucher"),
    //   console.log("denomination :", localStorage.getItem("denomination"));

    // console.log("ans1 :", localStorage.getItem("ans1"));
    // console.log("ans2 :", localStorage.getItem("ans2"));
    // console.log("ans3 :", localStorage.getItem("ans3"));
    // console.log("ans4 :", localStorage.getItem("ans4"));
    // console.log("ans5 :", localStorage.getItem("ans5"));
    var username = process.env.REACT_APP_API_USERNAME;
    var password = process.env.REACT_APP_API_PASSWORD;
    try {
      const responseuserform = axios({
        method: "post",
        url: process.env.REACT_APP_API_Link + "userForm",
        data: data,
        headers: {
          Authorization: `Basic ${Buffer.from(
            `${username}:${password}`,
            "utf8"
          ).toString("base64")}`,
          "Access-Control-Allow-Origin": process.env.REACT_APP_API_ORIGIN,
        },
      });
      responseuserform
        .then((response) => {
          isformIsValid = true;
          var value_userform = response.data.status;
          
          setValueUserform(value_userform)
      

          localStorage.setItem("isVoucher", true);
          localStorage.setItem("returnpage", false);
          localStorage.setItem("userformopen", false);
          localStorage.setItem("userform", false);
          localStorage.setItem("Thankyou", true);
          localStorage.removeItem("ans1");
          localStorage.removeItem("ans2");
          localStorage.removeItem("ans3");
          localStorage.removeItem("ans4");
          localStorage.removeItem("ans5");
          props.history.push({
            pathname: "/Thankyou",
            state: {
              name: fields.name,
              lname: fields.lname,
              address: fields.address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phonenumber: fields.phonenumber,
              phonenumber1: fields.phonenumber1,
              phonenumber2: fields.phonenumber2,
              emailid: fields.emailid,
              card: selectCard,
              popup: response.data.data.popup,
            },
          });
          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          // alert(err);
          isformIsValid = false;
          let errorData = {};
          errorData["name"] =
            err.response?.data?.message &&
            err.response?.data?.message?.fname &&
            err.response?.data?.message?.fname[0];
          errorData["lname"] =
            err.response?.data?.message &&
            err.response?.data?.message?.lname &&
            err.response?.data?.message?.lname[0];
          errorData["address"] =
            err.response?.data?.message &&
            err.response?.data?.message?.address &&
            err.response?.data?.message?.address[0];
          errorData["city"] =
            err.response?.data?.message &&
            err.response?.data?.message?.city &&
            err.response?.data?.message?.city[0];
          errorData["state"] =
            err.response?.data?.message &&
            err.response?.data?.message?.state &&
            err.response?.data?.message?.state[0];
          errorData["zipcode"] =
            err.response?.data?.message &&
            err.response?.data?.message?.zip &&
            err.response?.data?.message?.zip[0];
          errorData["phonenumber"] =
            err.response?.data?.message &&
            err.response?.data?.message?.phone &&
            err.response?.data?.message?.phone[0];
          errorData["emailid"] =
            err.response?.data?.message &&
            err.response?.data?.message?.email &&
            err.response?.data?.message?.email[0];
          errorData["checkederrortwo"] =
            err.response?.data?.message &&
            err.response?.data?.message?.size &&
            err.response?.data?.message?.size[0];
          errorData["checkederrorfive"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          errorData["checkedboxerrornine"] =
            err.response?.data?.message &&
            err.response?.data?.message?.choice &&
            err.response?.data?.message?.choice[0];
          // errorData["checkederrorsix"] =
          // err.response?.data?.message &&
          // err.response?.data?.message?.choice &&
          // err.response?.data?.message?.choice[0];
          errorData["certificate"] =
            err.response?.data?.message &&
            err.response?.data?.message?.certificate &&
            err.response?.data?.message?.certificate[0];
            // console.log(errorData);
          // saveErrors(errorData);
          setErrors(errorData);
          ref?.current?.focus();
        });
    } catch (err) {
      if (err.response.status == 429 || err.response.status == "429") {
        alert("Max Limit Reached. Please wait.");
      }
      isformIsValid = false;
      let errorData = {};
      errorData["name"] = err.response.data.message.fname[0];
      errorData["lname"] = err.response.data.message.lname[0];
      errorData["address"] = err.response.data.message.address[0];
      errorData["city"] = err.response.data.message.city[0];
      errorData["state"] = err.response.data.message.state[0];
      errorData["zipcode"] = err.response.data.message.zip[0];
      errorData["phonenumber"] = err.response.data.message.phone[0];
      errorData["emailid"] = err.response.data.message.phone[0];
      errorData["checkederrorfive"] = err.response.data.message.choice[0];
      errorData["checkedboxerrornine"] = err.response.data.message.choice[0];
      errorData["certificate"] = err.response.data.message.certificate[0];
      // saveErrors(errorData);
      setErrors(errorData);
      textInput.current.focus();
    }
    return isformIsValid;
  }
  (e) => {
    let fields = fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "The fname field is required.";
      console.log(formIsValid)
     
      setErrors(errors);
      return false;
    }
    if (!state.i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";
      setState({
        errors: errors,
      });
      return false;
    }
   
    setErrors(errors);
    return formIsValid;
  }
  const moveOnMax = (field, nextFieldID) => {
    if (field.value.length >= field.maxLength) {
      alert(field.value.length);
      nextFieldID.focus();
    }
  };
    const open = localStorage.getItem("userform");
    const Voucher = localStorage.getItem("voucher");
    const redirectpage = localStorage.setItem("returnpage", false);
    if (
      Voucher == "undefined" ||
      Voucher == "null" ||
      Voucher == null ||
      open == "false"
    ) {
      localStorage.setItem("returnpage", false);
      localStorage.removeItem("page");
      localStorage.removeItem("ans1");
      localStorage.removeItem("ans2");
      localStorage.removeItem("ans3");
      localStorage.removeItem("ans4");
      localStorage.removeItem("ans5");
      localStorage.setItem("userformopen", true);
      localStorage.setItem("userform", false);
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="container-fluid">
          <Header />
          {/* <HeaderLower /> */}
          <div id="main-registration-container">
            <div className="white-box form">
              <h1>Reward Card Redemption Site</h1>
              <h2>Contact Information</h2>
              <form
                name="userRegistrationForm"
                className="form-outer"
                onSubmit={submituserRegistrationForm}
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="formsub">
                      
                      First Name: <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      style={
                        errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      name="name"
                      tabIndex="1"
                      id="fname"
                      className="searchBox_deals vc-validate"
                      ref={errors.name ? ref : null}
                      value={fields.name || ""}
                      autoFocus=""
                      onChange={handleChange}
                    />
                    <div className="errorMsg">{errors.name}</div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      
                      Last Name <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="lname"
                      style={
                        errors.lname && !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      placeholder="Last Name"
                      tabIndex="1"
                      id="lname"
                      className="searchBox_deals vc-validate"
                      value={fields.lname || ""}
                      ref={
                        errors.lname && !errors.name
                          ? ref
                          : null
                      }
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.lname && !errors.name
                        ? errors.lname
                        : null}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-6">
                    <label required className="formsub">
                      Address <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      style={
                        errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      // name="address"
                      placeholder="Address"
                      name="address"
                      tabIndex="1"
                      id="address"
                      ref={
                        errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      className="searchBox_deals vc-validate"
                      autoFocus=""
                      value={fields.address || ""}
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.address
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      
                      City <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      style={
                        errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      tabIndex="4"
                      placeholder="City"
                      ref={
                        errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      value={fields.city || ""}
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      
                      {errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.city
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      State <span className="red_point">*</span>
                    </label>

                    <select
                      style={
                        errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      ref={
                        errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      name="state"
                      id="state"
                      tabIndex="5"
                      className="select_1 dropdown"
                      onChange={handleChange}
                    >
                      <option >
                        Select State
                      </option>
                {/* {console.log(states)} */}
                      {states.map((option) => {
                        return (
                          <>
                            <option value={option.code}>
                            {option.state_name}
                            {/* <option>Hello</option> */}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <div className="errorMsg">
                      {errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.state
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      Zip Code <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      id="zipcode"
                      style={
                        errors.zipcode &&
                        !errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      name="zipcode"
                      maxLength="10"
                      tabIndex="6"
                      placeholder="Zip/Postal Code"
                      value={fields.zipcode || ""}
                      ref={
                        errors.zipcode &&
                        !errors.state &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      
                      {errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.zipcode
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="phone-outer">
                      <label className="formsub">
                        Phone No <span className="red_point">*</span>
                      </label>
                      <input
                        type="text"
                        className="phone"
                        style={
                          errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        ref={
                         errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                          ? ref
                          : null
                        }
                        maxLength="3"
                        id="phonenumber"
                        name="phonenumber"
                        tabIndex="7"
                        value={fields.phonenumber || ""}
                        onChange={handleChange}
                      />
                      -
                      <input
                        type="text"
                        className="phone"
                        style={
                          errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        ref={
                          errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                          ? ref
                          : null
                        }
                        maxLength="3"
                        id="phonenumber2"
                        name="phonenumber1"
                        tabIndex="8"
                        // onKeyUp="moveOnMax(this,'phonenumber2')"
                        value={fields.phonenumber1 || ""}
                        onChange={handleChange}
                      />
                      -
                      <input
                        type="text"
                        className="phone"
                        style={
                          errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                            ? { border: "1px solid red" }
                            : null
                        }
                        ref={
                          errors.phonenumber &&
                          !errors.state &&
                          !errors.zipcode &&
                          !errors.city &&
                          !errors.address &&
                          !errors.lname &&
                          !errors.name
                          ? ref
                          : null
                        }
                        maxLength="4"
                        id="phonenumber3"
                        name="phonenumber2"
                        tabIndex="9"
                        // onKeyUp="moveOnMax(this,'email')"
                        value={fields.phonenumber2 || ""}
                        onChange={handleChange}
                      />
                      <div className="errorMsg">
                        {errors.phonenumber &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? errors.phonenumber
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="formsub">
                      Email <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailid"
                      tabIndex="10"
                      ref={
                        errors.emailid &&
                        !errors.state &&
                        !errors.phonenumber &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      style={
                        errors.emailid &&
                        !errors.state &&
                        !errors.phonenumber &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      // value=""
                      id="emailid"
                      placeholder="E-mail"
                      value={fields.emailid || ""}
                      onChange={handleChange}
                    />
                    <div className="errorMsg">
                      {errors.emailid &&
                      !errors.phonenumber &&
                      !errors.state &&
                      !errors.zipcode &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.emailid
                        : null}
                    </div>
                  </div>
                  <br />
                  <h2>Bostonfam Rest Test</h2>
                  <div className="clearfix"></div>
                  <div className="col-md-12" id="card-section">
                    <div className="row">
                      <span style={{ float: "left" }} id="color_msg22"></span>
                      <div className="errorMsg">
                        {errors.checkederrorfive}
                      </div>
                      {value_three.map((insdex) => {
                        return (
                          <>
                            <span
                              className="error_message_forimage"
                              id="color_msg22"
                            ></span>
                            <div className="check-boexs cen">
                              <div className="reward">
                                <label>
                                  <input
                                    type="radio"
                                    name="imgname"
                                    id={insdex.name}
                                    key={insdex.name}
                                    value={insdex.name}
                                    onChange={handleChangecheckboxfive}
                                  />
                                  <span className="insdex">{insdex.name}</span>
                                  {
                                    insdex.image ?
                                    <img
                                      src={
                                        process.env.REACT_APP_API_IMAGES +
                                        insdex.image
                                      }
                                      className="imgcolor img-responsive"
                                    /> :
                                    <div class="pirds pirds2">
                                      <span class="rdcrdtop">
                                        <table border="0">
                                          <tr>
                                            <td>{insdex.name}</td>
                                          </tr>
                                        </table>
                                      </span>
                                      <span class="rdcrd">REWARD CARD</span>
                                    </div>
                                  }
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <span style={{ color: "red" }} id="clr_msg"></span>
                  </div>
                  <span
                    className="error"
                    style={{ display: "none" }}
                    id="radio_msg"
                  />
                  <br></br>
                  <div></div>
                </div>
                <label className="agree label">
                  <input
                    type="checkbox"
                    id="myCheck"
                    name="checkbox"
                    className="conditions"
                    value={fields.checkbox}
                    onChange={handleChangecheckbox}
                  />
                  <span>I agree to</span>
                  <Model />
                  <br />
                  <div className="errorMsg">{errors.agree}</div>
                  <br />
                  {/* {state.show && <ContentPopup />} */}
                </label>
                <hr />
                <input
                  type="submit"
                  name="submit"
                  id="submit"
                  value="SUBMIT"
                  className="sub-btn1 button Formfirst"
                  onClick={handleClick}
                ></input>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
export default UserForm;
