import {React,useState} from "react";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Recaptcha from "react-recaptcha";

const UserformSurvey = (props) => {
  const [ans1 , setAns1] = useState(" ");
  const [ans2 , setAns2] = useState(" ");
  const [ans3 , setAns3] = useState(" ");
  const [ans4 , setAns4] = useState(" ");
  const [ans5 , setAns5] = useState(" ");
  const [errors , setErrors] = useState({});
  const [i_agree , setI_agree] = useState(false);
  const [captcha , setCaptcha] = useState(false);
  const [signoutTime , setSignoutTime] = useState(180000);



    localStorage.setItem("returnpage", true);
    localStorage.setItem("page", "/UserFormSurvey");
    
  
    timeout();
    function timeout() {
      // alert(signoutTime);
      setTimeout(function() {
          logout();
        },
        signoutTime
      );
    };



  const logout = () => {
    destroy();

  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem('voucher');
    localStorage.removeItem('page');
    localStorage.removeItem('ans1');
    localStorage.removeItem('ans2');
    localStorage.removeItem('ans3');
    localStorage.removeItem('ans4');
    localStorage.removeItem('ans5');
    window.location.replace("/");
  };

   const handleChangeans1 = (event) => {
    
    const ans1 = event.target.value;
    setAns1(ans1);
    localStorage.setItem("ans1", ans1);
    console.log(ans1);
    
    
  };
  const handleChangeans2 = (event)=> {
    
    const ans2 = event.target.value;
    setAns2(ans2);
    localStorage.setItem("ans2", ans2);
    console.log(ans2);
  };
  const handleChangeans3 = (event)=> {
    
    const ans3 = event.target.value;
    setAns3(ans3);
    localStorage.setItem("ans3", ans3);
    console.log(ans3);
  };
  const handleChangeans4 = (event)=> {
   
    const ans4 = event.target.value;
    setAns4(ans4);
    localStorage.setItem("ans4", ans4);
    console.log(ans4);

  };
  const handleChangeans5 = (event)=> {
   
    const ans5 = event.target.value;
    setAns5(ans5);
    localStorage.setItem("ans5", ans5);
    console.log(ans5);
  };


  const verifyCallback = (response) => {
    let errors = {};
    if (response) {
      setCaptcha({
        captcha: true,
      });
    } else {
      setErrors(
        errors
      );
      return false;
    }
  };


  const submituserRegistrationForm=(e) =>{
    e.preventDefault();
    if (validate()) {
      localStorage.setItem('userformopen', 'ture');
        if (captcha) {
          props.history.push({
            pathname: "/Userform",
            // search: '?query=abc',
            state: {
              ans1: ans1,
              ans2: ans2,
              ans3: ans3,
              ans4: ans4,
              ans5: ans5,
            },
          });
        }
      
    } else {
      localStorage.setItem('userformopen', false);
    }
  }


  const validate=()=> {
    let errors = {};
    let formIsValid = true;
    const token_value = localStorage.getItem("voucher");
    let data ={
      ans1 : ans1,
      ans2 : ans2,
      ans3 : ans3,
      ans4 : ans4,
      ans5 : ans5,
    }
    console.log("1st:",ans1);
    console.log("2nd:",ans2);
    console.log("3rd:",ans3);
    console.log("4th:",ans4);
    console.log("5th:",ans5);


    if (ans1 == " ") {
      formIsValid = false;
      errors["ans1"] = "Please select the option";
      setErrors(
        errors
      );
      return false;
    }

    if (ans2 == " ") {
      formIsValid = false;
      errors["ans2"] = "Please select the option";
      setErrors(
        errors
      );
      return false;
    }
    if (ans3 == " ") {
      formIsValid = false;
      errors["ans3"] = "Please select the option";
      setErrors(
        errors
      );
      return false;
    }

    if (ans4 == " ") {
      formIsValid = false;
      errors["ans4"] = "Please select the option";
      setErrors(
        errors
      );
      return false;
    }
    if (ans5 == " ") {
      formIsValid = false;
      errors["ans5"] = "Please select the option";
      setErrors(
        errors
      );
      return false;
    }
  


    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(
        errors
      );
      return false;
    }
    return formIsValid;
  };
  const onLoadRecaptcha = () => {}


    const Voucher = localStorage.getItem("voucher");
    const userform = localStorage.getItem("userform");
    // alert(userform);
    if(userform == 'true') {
        return <Redirect to="/UserForm" />;
    }
    
    const redirectpage = localStorage.setItem("returnpage", true);
    if (Voucher == "undefined" || Voucher == "null" || Voucher == null) {
      localStorage.setItem("returnpage", false);
      localStorage.setItem("userform", false);
      localStorage.removeItem('page');
      localStorage.removeItem('ans1');
      localStorage.removeItem('ans2');
      localStorage.removeItem('ans3');
      localStorage.removeItem('ans4');
      localStorage.removeItem('ans5');
      return <Redirect to="/" />;
    }
    
  return (
<>

<div className="container-fluid">
<Header />   
{/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association.</div> */}
<div className="white-box form">

<form name="userinfo" className="form-outer register clearfix" id="userinfo" onSubmit={submituserRegistrationForm} method="post" action="">
<div className="row">
	
	

{/* Survey section */}
<h1 style={{textAlign:"center"}}>Online Customer Survey</h1>
<label><b>1</b>. On a scale from 1 to 5, please rate your overall satisfaction on your Test Rest experience with the Sales Associate: <span>*</span></label>
 <div className="boxheading">
 <label className="heading1"><b>Highly Dissatisfied</b></label> 
 <label className="heading1 ml-5"><b> Highly Satisfied</b></label> 
</div>
 <br/>

   <label className="inline">
  <input type="radio" id="satisfaction_radio" name="satisfaction_radio" value="1" onChange={handleChangeans1} /> 1
  </label>
  
 <label className="inline ml-2">
  <input type="radio" id="satisfaction_radio" name="satisfaction_radio" value="2" onChange={handleChangeans1}/> 2
  </label>
   <label className="inline ml-2">
  <input type="radio" id="satisfaction_radio" name="satisfaction_radio" value="3" onChange={handleChangeans1}/> 3
  </label>
 <label className="inline ml-2">
  <input type="radio" id="satisfaction_radio" name="satisfaction_radio" value="4" onChange={handleChangeans1}/> 4
  </label>
  <label className="inline ml-2">
  <input type="radio" id="satisfaction_radio" name="satisfaction_radio" value="5" onChange={handleChangeans1}/> 5
  </label>

<label className="inline ml-2">
  <div className="errorMsg">
            {errors.ans1}
    </div>
  <span style={{color:"red"}} id="satisfaction_msg"></span>

    </label>
<hr/>

  <label><b>2</b>. Did the Sales Associate begin the Test Rest presentation by introducing you to a Tempur-Pedic mattress?<span>*</span></label>
  <label className="inline">
  <input type="radio" id="mattress_radio" name="mattress_radio" value="Yes" onChange={handleChangeans2}/> Yes
  </label>
<label className="inline ml-2">
  <input type="radio" id="mattress_radio" name="mattress_radio" value="No" onChange={handleChangeans2}/> No
  </label>
  <label className="inline ml-2">
  <div className="errorMsg">
            {errors.ans2}
    </div>
  <span style={{color:"red"}} id="mattress_msg"></span>

    </label>
<hr/>
  <label><b>3</b>. Did the Sales Associate demonstrate the features of a power base during your Test Rest experience?<span>*</span></label>
   <label className="inline">
  <input type="radio" id="experience_radio" name="experience_radio" value="Yes" onChange={handleChangeans3}/> Yes
  </label>
<label className="inline ml-2">
  <input type="radio" id="experience_radio" name="experience_radio" value="No" onChange={handleChangeans3}/> No
  </label>

<label className="inline ml-2">
  <div className="errorMsg">
            {errors.ans3}
    </div>
  <span style={{color:"red"}} id="experience_msg"></span>

    </label>

<hr/>

    <label><b>4</b>. Were you custom fit for a pillow based on your preferred sleep position (side, back, or stomach)?<span>*</span></label>
	 <label className="inline">
  <input type="radio" id="stomach_radio" name="stomach_radio" value="Yes" onChange={handleChangeans4}/> Yes
  </label>
 <label className="inline ml-2">
  <input type="radio" id="stomach_radio" name="stomach_radio" value="No" onChange={handleChangeans4}/> No
  </label>

  <label className="inline ml-2">
  <div className="errorMsg">
            {errors.ans4}
    </div>
  <span style={{color:"red"}} id="stomach_msg"></span>

    </label>
  <hr/>


    <label><b>5</b>. On a scale from 1 to 5, based on your experience how likely are you to purchase your next mattress at Bostonfam Rest Test?<span>*</span></label>
    <div className="boxesheading">
  <label className="heading1"><b>Not At All Likely</b></label>
  <label className="heading1 ml-3"><b> Extremely Likely</b></label>
</div>
  <br/>
   <label className="inline">
   <input type="radio" id="store_radio" name="store_radio" value="1" onChange={handleChangeans5}/> 1
   </label>
    <label className="inline ml-2">
  <input type="radio" id="store_radio" name="store_radio" value="2" onChange={handleChangeans5}/> 2
  </label>
  <label className="inline ml-2">
  <input type="radio" id="store_radio" name="store_radio" value="3" onChange={handleChangeans5}/> 3
  </label>
  <label className="inline ml-2">
  <input type="radio" id="store_radio" name="store_radio" value="4" onChange={handleChangeans5}/> 4
  </label>
  <label className="inline ml-2">
  <input type="radio" id="store_radio" name="store_radio" value="5" onChange={handleChangeans5}/> 5
  </label>

  <label className="inline ml-2">
  <div className="errorMsg">
            {errors.ans5}
    </div>
  <span style={{color:"red"}} id="store_msg"></span>
    </label>

<br/>
<br/>

                   <Recaptcha
                        sitekey= {process.env.REACT_APP_API_GOOGLESITEKEY}
                        render="explicit"
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                      />
                      
                      <div className="errorMsg">
                        {errors.checkederrorcaptcha}
                      </div>

              <span style={{color:"red"}} id="response_msg"></span>
            <br/>
			{/* <!-- <input type="checkbox" id="terms" value="terms"> I agree to <a href="#myModal" data-toggle="modal" >term and conditions </a> <br>
			<span style="color:red"  id="terms_msg"></span> --> */}


	 

</div>
  <input onclick="myTimeout()" type="submit"  className="submit-btn survey" name="submit1" value="Submit and Move Next"/>
</form>



</div>  
</div>
<Footer />
</>
    );
  }
export default UserformSurvey;
